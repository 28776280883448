<template>
  <div>
    <div class="conMsg">
      <div class="return" v-if="shopId" @click="back">
        <img class="backImg" src="@/assets/images/back.png" alt />
      </div>
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />商品列表
        </div>
      </div>
      <div class="table">
        <!--列表-->
        <el-table
          :data="goodsList"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <el-table-column label="商品名称" prop="goods_name" />
          <ElTableColumn label="服务/商品">
            <template slot-scope="{ row }">
              <div v-if="row.is_project == 1">商品</div>
              <div v-if="row.is_project == 2">服务</div>
            </template>
          </ElTableColumn>
          <el-table-column label="数量" prop="number" />
          <el-table-column label="标准单价" prop="goods_price" />
          <el-table-column label="销售单价" prop="pay_price" />
          <el-table-column label="销售金额" prop="order_goods_price" />
          <el-table-column label="商品/服务类型">
            <template slot-scope="{ row }">
              <div v-if="row.project_type == 1 && row.is_project ==2">按次</div>
              <div v-if="row.project_type == 2 && row.is_project ==2">按天</div>
              <div v-if=" row.is_project ==1">--</div>
            </template>
          </el-table-column>
          <el-table-column label="服务">
            <template slot-scope="{ row }">
              <div v-if="row.project_type == 1 ">{{row.service_number}}次</div>
              <div v-if="row.project_type == 2 ">{{row.start_time}}-{{row.end_time}}</div>
              <div v-if=" row.is_project ==1">--</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
          <span>订单总额：</span>
          <span class="peril">{{this.detail.total_amount}}</span>
        </div>
      </div>
    </div>

    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
      </div>
      <el-descriptions class="margin-top" :column="4" border>
        <el-descriptions-item>
          <template slot="label">客户姓名</template>
          {{detail.consignee}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间</template>
          {{detail.create_time}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">业务类型</template>
          {{detail.business_type == 1?'项目':'疤痕'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">销售员</template>
          {{detail.sale_name}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">门店名称</template>
          {{detail.shop_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">录单员</template>
          {{detail.operator_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">是否体验</template>
            {{detail.is_experience==0?'否':'是'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">外联人</template>
            {{detail.outreahc_name?detail.outreahc_name:'无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">转介绍人</template>
            {{detail.introduction_name?detail.introduction_name:'无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">销售组织</template>
            {{detail.sales_name?detail.sales_name:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发货组织</template>
            {{detail.settlement_name?detail.settlement_name:'暂无'}}
          </el-descriptions-item>
          <template slot="label">订单类型</template>
          {{detail.order_type==1?'新单':'补单'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">订单状态</template>
          {{detail.status_msg}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">支付时间</template>
          {{detail.pay_time?detail.pay_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">成交时间</template>
          {{detail.deal_time?detail.deal_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">完成时间</template>
          {{detail.complete_time?detail.complete_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">优惠券</template>
            {{detail.coupon_name?detail.coupon_name:'未使用'}}
          </el-descriptions-item>
          <template slot="label">订单总额</template>
          {{detail.total_amount}}(元)
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">优惠金额</template>
          {{detail.discount_amount}}(元)
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">实收本金</template>
          {{detail.pay_amount}}(元)
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">实收赠金</template>
          {{detail.gift_amount}}(元)
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">实收总额</template>
          {{detail.order_amount}}(元)
        </el-descriptions-item>
      </el-descriptions>

      <div class="submit" v-if="detail.status == 0">
        <el-button type="primary" size="mini" class="mainBtn" @click="hint('该订单已支付',1)">确认支付</el-button>
        <el-button size="mini" class="mainBtn" @click="hint('取消该订单',4)">取消订单</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { orderDetail, orderGoodsDetail } from "@/api/order";
import { checkOrder } from "@/api/shop";
export default {
  data() {
    return {
      detail: {},
      goodsList: [],
      shopId: ""
    };
  },
  mounted() {
    this.getDetail();
    this.shopId = this.$route.query.id;
  },
  methods: {
    // 获取详情信息
    getDetail() {
      orderDetail({ order_id: this.$route.query.id }).then(res => {
        this.detail = res;
      });
      orderGoodsDetail({ order_id: this.$route.query.id }).then(res => {
        this.goodsList = res.goodsList;
      });
    },
    // 返回
    back() {
      this.$router.push(
        "./shopDetail?id=" + this.$route.query.shopId + "&activeName=second"
      );
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 提价
    confirm(type) {
      checkOrder({ order_id: this.$route.query.id, status: type }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getDetail();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  position: relative;
  .return {
    position: fixed;
    right: 20px;
    top: 71px;
    // background: #fff;
    z-index: 999;
    .backImg {
      margin-right: 14px;
      width: 32px;
      height: 100%;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
  .table {
    border-top: 1px solid #eee;

    .total {
      text-align: right;
      padding: 10px 64px 10px 80px;
      box-sizing: border-box;
      background: #f9fafc;
      border: 1px solid #eee;
      border-top: 0;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .submit {
    text-align: center;
  }
  .mainBtn {
    margin: 50px 20px;
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background: #f9fafc;
}
</style>
